<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="146px" height="168px" version="1.1"
       style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
       viewBox="0 0 47.38 54.43" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Camada_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"/>
      <path class="fil0"
            d="M9.99 9.74l12.41 3.24c-1.27,0.21 -2.5,0.62 -3.65,1.26 -3.24,1.82 -5.27,5.14 -6.04,9.17l13.22 -7.67c-1.51,2.29 -4.16,7.99 -5.35,15l7.44 0c-0.65,-7 0.1,-13.72 1.07,-15l10.98 12.55c0.62,-4.05 -0.19,-7.86 -2.64,-10.66 -1.07,-1.23 -2.39,-2.15 -3.86,-2.81l12.56 3.28c-1.75,-3.7 -4.56,-6.4 -8.16,-7.34 -1.32,-0.34 -2.66,-0.42 -3.99,-0.28l10.29 -5.78c-3.84,-1.45 -7.73,-1.43 -10.97,0.39 -1.92,1.07 -3.41,2.68 -4.47,4.65 -0.36,-2.14 -1.21,-4.09 -2.62,-5.71 -2.45,-2.79 -6.11,-4.11 -10.21,-4.03l7.38 8.44c-0.83,-0.48 -1.72,-0.85 -2.67,-1.1 -3.6,-0.94 -7.37,0.03 -10.72,2.4zm20.9 24.44l-6.23 0c0,0 0,0 0,-0.01l-8.32 0c0,0.01 0,0.01 0,0.02 -9.04,0.08 -16.34,7.42 -16.34,16.48l0 3.76 47.38 -3.73 0 -0.03c0,-9.11 -7.38,-16.49 -16.49,-16.49z"/></g></svg>
</template>
<script>
export default {
  name: 'iconSeason'
}
</script>
