<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="146px" height="168px" version="1.1"
       style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
       viewBox="0 0 10.43 12" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Camada_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"/>
      <path class="fil0"
            d="M7.51 6.77l-1.83 0 0 -1.83 1.83 0 0 1.83zm0 2.76l-1.83 0 0 -1.84 1.83 0 0 1.84zm-2.75 -2.76l-1.84 0 0 -1.83 1.84 0 0 1.83zm0 2.76l-1.84 0 0 -1.84 1.84 0 0 1.84zm4.69 -6.79l-3.17 -2.38c-0.63,-0.48 -1.5,-0.48 -2.13,0l-3.17 2.38c-0.62,0.46 -0.98,1.19 -0.98,1.97l0 7.29 10.43 -0.89 0 -6.4c0,-0.78 -0.36,-1.51 -0.98,-1.97z"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'iconSale'
}
</script>
