<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="146px" height="168px" version="1.1"
       style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
       viewBox="0 0 14.22 16.36" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Camada_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"/>
      <path class="fil0"
            d="M9.52 13.67l-1.64 -1.89 1.9 -1.64 1.63 1.9 -1.89 1.63zm3.58 -5.72l-1.01 -1.16c-1.32,-1.52 -3.4,-1.97 -5.17,-1.29l-4.75 -5.5 -2.17 1.87 0 2.15 1.62 -1.39 0.69 0.8 -1.61 1.4 0.7 0.81 1.61 -1.4 0.7 0.81 -1.62 1.4 1.59 1.84c-0.94,1.65 -0.8,3.79 0.51,5.31l1.02 1.16c1.66,1.93 4.58,2.15 6.51,0.48l0.9 -0.77c1.93,-1.67 2.15,-4.59 0.48,-6.52z"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'iconRental'
}
</script>
