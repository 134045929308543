/* eslint-disable class-methods-use-this */
import Service from '../Service';
import http from "@/http";

class DigitalFairService extends Service {
    constructor() {
        super('/digital-fairs');
    }

    getClients(params) {
        return http.get(`${this.baseUrl}/clients/${params}`);
    }

    getCities(params) {
        return http.get(`${this.baseUrl}/cities/${params}`);
    }
}

const digitalFairService = new DigitalFairService();
export default digitalFairService;
