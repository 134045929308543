<template>
  <div>
    <loading :enable="loading" />
    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Filtros</h5>
      <b-row class="px-3">
        <b-col lg="3">
          <b-form-group label="Anunciante">
            <vue-bootstrap-typeahead
                :data="clients"
                v-model="clientSearch"
                :serializer="s => s.name"
                placeholder="Digite o nome ou código do cliente"
                @hit="filter.advertiser = $event.value"
                @reset="filter.advertiser = null"
                :minMatchingChars="1"
                ref="client"
            />
          </b-form-group>
        </b-col>

        <b-col lg="3">
          <b-form-group label="Cidade do anunciante">
            <vue-bootstrap-typeahead
                :data="advertiserCities"
                v-model="advertiserCitiesSearch"
                :serializer="s => s.name"
                placeholder="Digite a cidade do anunciante"
                @hit="filter.advertiserCity = $event.value"
                :minMatchingChars="1"
                @reset="filter.advertiserCity = null"
                ref="advertiserCity"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col lg="12" class="py-3">
        <b-button variant="primary" @click="handleFilter" class="mr-2" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          <i class="fas fa-search" v-if="!loading"></i> Pesquisar
        </b-button>
        <b-button variant="danger" @click="clearFilters" class="mr-2">
          <i class="fas fa-broom"></i> Limpar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Total de imóveis na feira</h5>

      <b-row class="p-3">
        <b-col>
          <div class="widget d-flex w-100">

            <div class="icon-container d-flex align-items-center justify-content-center">
              <icon-launch class="icon" />
            </div>

            <div class="d-flex align-items-start justify-content-between flex-column ml-3 w-100">
              <span class="title">Lançamentos</span>

              <div class="progress w-100">
                <div class="segment" :style="{ width : totals.launch.percent + '%'}"></div>
              </div>

              <span class="value">{{ totals.launch.fair.toLocaleString('pt-BR') }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100">

            <div class="icon-container d-flex align-items-center justify-content-center">
              <icon-sale class="icon" />
            </div>

            <div class="d-flex align-items-start justify-content-between flex-column ml-3 w-100">
              <span class="title">Venda</span>
              <div class="progress w-100">
                <div class="segment" :style="{ width : totals.sale.percent + '%'}"></div>
              </div>
              <span class="value">{{ totals.sale.fair.toLocaleString('pt-BR') }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100">

            <div class="icon-container d-flex align-items-center justify-content-center">
              <icon-rental class="icon" />
            </div>

            <div class="d-flex align-items-start justify-content-between flex-column ml-3 w-100">
              <span class="title">Locação</span>
              <div class="progress w-100">
                <div class="segment" :style="{ width : totals.rental.percent + '%'}"></div>
              </div>
              <span class="value">{{ totals.rental.fair.toLocaleString('pt-BR') }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100">

            <div class="icon-container d-flex align-items-center justify-content-center">
              <icon-season class="icon" />
            </div>

            <div class="d-flex align-items-start justify-content-between flex-column ml-3 w-100">
              <span class="title">Temporada</span>
              <div class="progress w-100">
                <div class="segment" :style="{ width : totals.season.percent + '%'}"></div>
              </div>
              <span class="value">{{ totals.season.fair.toLocaleString('pt-BR') }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>

      <div class="mt-4 registry-container">
        <div class="registry" v-for="item in items" :key="item.subsee_reference">
          <div class="advertiser">
            <h3>{{ item.subsee_reference + ' - ' + item.advertiser }}</h3>
          </div>

          <div class="resource-container" v-for="(total, index) in item.totals" :key="`total-${index}-${item.subsee_reference}`">
            <div class="resource">
              <div class="business">
                <icon-launch v-if="total.business === 'Lançamento'"/>
                <icon-sale v-if="total.business === 'Venda'"/>
                <icon-rental v-if="total.business === 'Locação'"/>
                <icon-season v-if="total.business === 'Temporada'"/>
                <span>{{total.business}}</span>
              </div>

              <div class="totals">
                <div class="info primary">
                  <span class="title">Total</span>
                  <span class="value">{{total.total}}</span>
                </div>

                <div class="info" :class="getColorClass(total)">
                  <span class="title">Feira</span>
                  <span class="value">{{total.fair}}</span>
                </div>

                <div class="info" :class="getColorClass(total)">
                  <span class="title">Perc.</span>
                  <span class="value">{{total.percent}}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import IconRental from "@/components/icons/IconRental";
import IconLaunch from "@/components/icons/IconLaunch";
import IconSale from "@/components/icons/IconSale";
import IconSeason from "@/components/icons/IconSeason";
import digitalFairService from "@/services/digitalFair/DigitalFairService";
import Loading from "@/components/shared/loading";
import _ from "underscore";

export default {
  components: {Loading, IconSeason, IconSale, IconLaunch, IconRental},
  data() {
    return {
      loading: false,
      clientSearch: '',
      clients: [],
      advertiserCities: [],
      advertiserCitiesSearch: '',
      filter: {},
      items: []
    }
  },
  mounted() {
    if(localStorage.getItem('importer-auth') !== 'bf:P5z#4n0}2kH[y') {
      this.$router.push({name: 'login'});
    }
    this.getAll();
  },
  computed: {
    totals() {

      let totals = {
        launch: {
          fair: 0,
          total: 0,
          percent: 0
        },
        sale: {
          fair: 0,
          total: 0,
          percent: 0
        },
        rental: {
          fair: 0,
          total: 0,
          percent: 0
        },
        season: {
          fair: 0,
          total: 0,
          percent: 0
        }
      }

      this.items.forEach((item) => {
        item.totals.map(total => {
          if(total.business === 'Lançamento') {
            totals.launch.fair += total.fair;
            totals.launch.total += total.total;
            totals.launch.percent = (totals.launch.fair / totals.launch.total) * 100;
          }

          if(total.business === 'Venda') {
            totals.sale.fair += total.fair;
            totals.sale.total += total.total;
            totals.sale.percent = (totals.sale.fair / totals.sale.total) * 100;
          }

          if(total.business === 'Locação') {
            totals.rental.fair += total.fair;
            totals.rental.total += total.total;
            totals.rental.percent = (totals.rental.fair / totals.rental.total) * 100;
          }

          if(total.business === 'Temporada') {
            totals.season.fair += total.fair;
            totals.season.total += total.total;
            totals.season.percent = (totals.season.fair / totals.season.total) * 100;
          }
        })
      });

      return totals;
    }
  },
  methods: {
    async getAll(filter) {
      this.loading = true;
      await digitalFairService.getList(filter).then((response) => {
        const {data} = response;
        this.items = data;
      });
      this.loading = false;
    },
    handleFilter() {
      this.items = [];
      this.getAll(this.filter);
    },
    clearFilters() {
      this.items = [];
      this.filter = {};
      this.$refs.advertiserCity.inputValue = '';
      this.$refs.client.inputValue = '';
      this.getAll();
    },
    async loadClients(query) {
      await digitalFairService.getClients(query).then((response) => {
        const {data} = response;
        this.clients = data.map((item) => {
          return {
            value: item.id,
            name: item.subsee_reference + ' - ' + item.name
          }
        })
      });
    },
    async loadCities(query) {
      await digitalFairService.getCities(query).then((response) => {
        const {data} = response;
        this.advertiserCities = data.map((item) => {
          return {
            value: item.id,
            name: item.name + ' - ' + item.uf
          }
        })
      });
    },
    getColorClass(total) {
      if(total.total === 0) {
        return 'success'
      }

      if(total.percent < 30) {
        return 'danger'
      }

      if(total.percent > 30) {
        return 'warning'
      }

      return 'success';
    }
  },
  watch: {
    clientSearch: _.debounce(function(addr) {
      if(!addr) {
        this.filter.advertiser = null;
      } else {
        this.loadClients(addr)
      }
    }, 500),
    advertiserCitiesSearch: _.debounce(function(addr) {
      if(!addr) {
        this.filter.advertiserCity = null;
      } else {
        this.loadCities(addr)
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>

.registry-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: 1580px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.registry {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: .32rem;
  background: #fff;
  padding: 1rem;

  .advertiser {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 1rem;
      font-weight: bold;
      color: #333;
    }
  }

  .resource-container {

    .resource {
      border: 1px solid #ccc;
      border-radius: .32rem;
      padding: .5rem;
      display: grid;
      grid-template-columns: 40% 60%;
      color: #333;
      font-size: 1rem;
      margin-bottom: 5px;


      .business {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        svg {
          fill: #6769f0;
          width: 35px;
          height: 35px;
        }
      }

      .totals {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: .8rem;
          border-radius: .32rem;
          color: #fff;
          padding: .5rem;

          &.danger {
            background: #ff4d4d;
          }

          &.success {
            background: #00d39b;
          }

          &.primary {
            background: #6769f0;
          }

          &.warning {
            background: #ffa000;
          }
        }
      }


      &.warning {
        background: #f90;
      }
    }
  }
}

.widget {

  border: 1px solid #ccc;
  border-radius: .32rem;
  padding: 10px;
  background: #6769f0;

  .icon-container {
    width: 70px;
    height: 70px;
  }

  svg.icon {
    fill: #fff;
    width: 40px;
    height: 40px;
  }

  .title {
    font-weight: 600;
    color: #fff;
  }

  .value {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
  }

  .progress {
    height: 3px;
    background: #a7a7ff;
    border-radius: .32rem;

    .segment {
      height: 3px;
      background: #fff;
      border-radius: .32rem;
    }
  }
}
</style>