<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="146px" height="168px" version="1.1"
       style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
       viewBox="0 0 21.84 25.12" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Camada_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"/>
      <path class="fil0"
            d="M10.92 12.74l-2.74 -2.73 2.74 -2.73 2.73 2.73 -2.73 2.73zm4.94 3.33l0.34 -3.43c0.35,-3.68 -0.8,-7.35 -3.19,-10.17l-2.09 -2.47 -2.09 2.47c-2.39,2.82 -3.54,6.49 -3.19,10.17l0.32 3.44 -5.96 3.57 1.26 5.47 5.7 -0.49 0 -3.19 1.58 0 0 3.06 1.58 -0.14 0 -4.64 1.59 0 0 4.5 1.59 -0.13 0 -2.65 1.58 0 0 2.51 6.09 -0.52 0.87 -3.78 -5.98 -3.58z"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'iconLaunch'
}
</script>
